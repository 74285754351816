import { Container, IconButton, Stack, Typography } from "@mui/material"
import { useLanguage } from "../../../state/language"
import { Adb, Apple } from "@mui/icons-material";
import PlatformDownload from "../components/PlatformDownload";

interface DownloadAppPageProps {};

const styles = {
    container: {
        textAlign: "center",
        px: {lg: 48, md: 36, xs: 4},
        py: {lg: 12, md: 6, xs: 2}
    },
    title: {
        fontSize: 48
    },
    stack: {
        py: 4,
        justifyContent: "space-evenly"
    }
}
export default function DownloadAppPage(props: DownloadAppPageProps) {
    const language = useLanguage();

    return <Container sx={styles.container}>
        <Typography sx={styles.title}>{language.portal.downloadApp}</Typography>
        <Stack direction="row" sx={styles.stack}>
            <PlatformDownload icon={<Adb fontSize="large" />} name="Android" />
            <PlatformDownload icon={<Apple fontSize="large" />} name="Apple"/>
        </Stack>
    </Container>
};