import Client from "inspector-common/lib/api/client/client";

class PortalClient extends Client {
    getAuthHeaders(): Promise<any> {
        return Promise.resolve({
            "Content-Type": "application/json"
        })
    }
};

const client = new PortalClient();
export default client;