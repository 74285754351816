import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface HeaderNavigationButtonProps {
    route: string
    children: string
    onClick?: () => void
};

const styles = {
    button: {
        display: "block"
    }
};

export default function HeaderNavigationButton(props: HeaderNavigationButtonProps) {
    const navigate = useNavigate();

    return <Button onClick={() => {
        if (props.onClick) {
            props.onClick();
        };
        navigate(props.route)
    }} variant="text" sx={styles.button}>{props.children}</Button>
}