import { Error } from "inspector-common/lib/model/common";
import { useCallback, useState } from "react";

interface APICallHook<Request, Response> {
    isLoading: boolean
    error?: Error<Object> | null
    data?: Response | null
    setData: (data: Response) => void
    execute: (request: Request) => Promise<Response | null>
};

function useAPICall<Request, Response>(defaultResponse: Response | null, makeAPICall: (request: Request) => Promise<Response>): APICallHook<Request, Response> {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(defaultResponse);


    const execute = async (request: Request): Promise<Response | null> => {
        try {
            setIsLoading(true);
            const response: Response = await makeAPICall(request);
            setData(response);
            setIsLoading(false);
            return response;
        } catch (e: any) {
            setError(e);
            setIsLoading(false);
            throw e;
        }
    };

    return {
        isLoading,
        error,
        data,
        execute: useCallback(execute, []), // to avoid infinite calls when inside a `useEffect`
        setData
    };
};

export { useAPICall };
export type { APICallHook };