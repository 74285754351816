import { useLanguage as useLanguageBase } from "inspector-common/lib/language"
import Language from "inspector-common/lib/language/language"
import { LanguageList } from "inspector-common/lib/language/list"
import { RecoilState, atom, useRecoilState } from "recoil";

export interface LanguageState {
    language: keyof LanguageList
};

export interface UseLanguageState {
    language: keyof LanguageList
    setLanguage: (language: keyof LanguageList) => void
}

const defaultLanguageState: LanguageState = {
    language: "nl"
};

export const languageState: RecoilState<LanguageState> = atom({
    key: "languageState",
    default: defaultLanguageState
});

export function useLanguage(): Language {
    const languageCode = useLanguageState().language
    return useLanguageBase(languageCode);
};

export function useLanguageState(): UseLanguageState {
    const [languageStateValue, setLanguageState] = useRecoilState(languageState);

    return {
        language: languageStateValue.language,
        setLanguage: (language: keyof LanguageList) => setLanguageState({language})
    };
}