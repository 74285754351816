import { Typography } from "@mui/material";
import { useLanguage } from "../../../state/language";

interface HeaderTitleProps {};

const styles = {
    title: {

    }
};

export default function HeaderTitle(props: HeaderTitleProps) {
    const language = useLanguage();
    return <img src={`${window.location.origin}/logo.png`} height={"60px"} alt="LOCI"/>
};