import { Box, Button, IconButton, Menu, MenuItem, Slide, Stack } from "@mui/material";
import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu"
import { Close } from "@mui/icons-material";
import HeaderTitle from "./HeaderTitle";
import HeaderNavigationButton from "./HeaderNavigationButton";
import { useLanguage } from "../../../state/language";
import LanguageSelect from "./LanguageSelect";

interface MobileHeaderProps {};

const styles = {
    menu: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 1,
        opacity: 100,
        backgroundColor: "white",
        alignContent: "flex-start",
        display: "block"
    }
}

export default function MobileHeader(props: MobileHeaderProps) {
    const [open, setOpen] = useState<boolean>(false);
    const language = useLanguage();
    return <>
        <IconButton onClick={() => setOpen(!open)}>
            <MenuIcon />
        </IconButton>
        <Slide direction="right" in={open} >
            <Box sx={styles.menu}>
                <Stack direction="column" sx={styles.menu} spacing={4}>
                    <Stack direction="row" justifyContent={"space-between"} >
                        <HeaderTitle />
                        <IconButton onClick={() => setOpen(false)} >
                            <Close />
                        </IconButton>
                    </Stack>

                    <HeaderNavigationButton route={"/pricing"} onClick={() => setOpen(false)}>{language.portal.pricing}</HeaderNavigationButton>
                    <HeaderNavigationButton route={"/documentation"} onClick={() => setOpen(false)}>{language.portal.documentation}</HeaderNavigationButton>
                    <HeaderNavigationButton route={"/download"} onClick={() => setOpen(false)}>{language.portal.downloadApp}</HeaderNavigationButton>
                    <HeaderNavigationButton route={"/signup"} onClick={() => setOpen(false)}>{language.portal.signup}</HeaderNavigationButton>
                    <Box pl={2}><LanguageSelect /></Box>
                </Stack>
            </Box>
        </Slide>
    </>
}