import { Stack, Typography } from "@mui/material";
import { MuiMarkdown } from "mui-markdown";
interface TermsAndConditionsPageProps {};


export default function TermsAndConditionsPage(props: TermsAndConditionsPageProps) {
    return <Stack direction="column" p={{xs: 2, lg: 12}} spacing={2}>
<Typography variant="h4">Terms of Use for LOCI</Typography>

<Typography variant="h6">1. Introduction</Typography>
<MuiMarkdown>{`
Welcome to LOCI, an application developed by AG IT-Solutions, a Belgian organization registered under BE0790.591.966

By using LOCI, you agree to comply with and be bound by the following Terms of Use. 

Please review them carefully. If you do not agree with any of these terms, you are prohibited from using this app.
`}</MuiMarkdown>
<Typography variant="h6">2. Definitions</Typography>
<MuiMarkdown>{`

Application: LOCI.

Developer: AG IT-Solutions.

User: Any individual who downloads, installs, or uses LOCI the LOCI web or mobile application.

Credits: Virtual currency that can be purchased and used within LOCI.
`}</MuiMarkdown>

<Typography variant="h6">3. Purchasing Credits</Typography>

<MuiMarkdown>{`
3.1 Users can buy credits through the application using the provided payment methods.

3.2 Once purchased, credits are non-refundable and non-transferable.

3.3 Users are obliged to pay for the credits they use. The charges for credits will be clearly stated at the point of purchase.

`}</MuiMarkdown>
<Typography variant="h6">4. Usage of Credits</Typography>
<MuiMarkdown>{`
4.1 Credits can be used to access various features and services within LOCI as specified in the app.

4.2 The availability of features and services that can be accessed with credits may change at the discretion of AG IT-Solutions without prior notice.
`}</MuiMarkdown>
<Typography variant="h6">5. Payment Obligations</Typography>
<MuiMarkdown>{`
5.1 Users must ensure they have sufficient funds to cover the cost of the credits they purchase.

5.2 Any disputes regarding payments or charges must be addressed directly with the payment provider used for the transaction.
`}</MuiMarkdown>
<Typography variant="h6">6. Limitation of Liability</Typography>
<MuiMarkdown>{`
6.1 The application is provided on an "as-is" and "as-available" basis.

6.2 AG IT-Solutions does not guarantee that the application will be free of errors, defects, malware, or other harmful components.

6.3 AG IT-Solutions shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or related to the use of or inability to use LOCI.

6.4 AG IT-Solutions is not responsible for any problems caused by the application, including but not limited to, data loss, system failures, or any other damages resulting from the use of the application.
`}</MuiMarkdown>
<Typography variant="h6">7. User Responsibilities</Typography>
<MuiMarkdown>{`
7.1 Users must use the application in compliance with all applicable laws and regulations.

7.2 Users are responsible for maintaining the confidentiality of their account information and for all activities that occur under their account.

7.3 Users must notify AG IT-Solutions immediately of any unauthorized use of their account or any other breach of security.
`}</MuiMarkdown>
<Typography variant="h6">8. Personal Information</Typography>
<MuiMarkdown>{`
8.1 Users are responsible for any personal information they enter into the application.

8.2 Users are responsible for obtaining agreement from their customers for the storage of personal information.

8.3 Customers can request to delete their personal data by sending an email to privacy@agits.be.
`}</MuiMarkdown>
<Typography variant="h6">9. Modifications to Terms of Use</Typography>
<MuiMarkdown>{`
9.1 AG IT-Solutions reserves the right to modify these Terms of Use at any time.

9.2 Users will be notified of any significant changes through the application or via the email address provided at registration.

9.3 Continued use of the application after such modifications constitutes acceptance of the revised terms.
`}</MuiMarkdown>
<Typography variant="h6">10. Governing Law</Typography>
<MuiMarkdown>{`
These Terms of Use shall be governed by and construed in accordance with the laws of the jurisdiction in which AG IT-Solutions is headquartered, without regard to its conflict of law provisions.
`}</MuiMarkdown>
<Typography variant="h6">11. Contact Information</Typography>
<MuiMarkdown>{`
For any questions or concerns regarding these Terms of Use, please contact AG IT-Solutions at:

Email: support@agits.be
Address: "Smissestraat 29, 2980 Zoersel"
By using LOCI, you acknowledge that you have read, understood, and agree to be bound by these Terms of Use.

`}</MuiMarkdown>
</Stack>
}