import { Box, Typography } from "@mui/material";
import { useLanguage } from "../../../state/language";

interface IntroProps {};

const styles = {
    box: {
        mb: 10,
        textAlign: "center",
        alignItems: "center"
    },
    title: {
        px: {lg: 48, md: 36, sx: 8},
        fontSize: 36,
        mb: 4,
        whiteSpace: "pre-line"

    },
    description: {
        px: {lg: 56, md: 12, sx: 4},
        fontSize: 18,
        color: "darkgrey",
    }
}
export default function Intro(props: IntroProps) {
    const language = useLanguage();
    console.log(language.portal.intro[0])

    return <Box sx={styles.box}>
        <Typography sx={styles.title}>
            {language.portal.intro[0]}
        </Typography>
        <Typography sx={styles.description}>
            {language.portal.intro[1]}
        </Typography>
        <Typography sx={styles.description}>
            {language.portal.intro[2]}
        </Typography>
    </Box>
}