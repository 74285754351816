import { Stack, Typography } from "@mui/material"

interface CardPriceProps {
    price: string
    comment: string
    highlighted?: boolean
};

const styles = {
    title: {
        fontSize: 36,
        fontWeight: "bold",
        maxWidth: "67%",
        whiteSpace: "nowrap"
    },
    comment: {
        color: "grey"
    },
    box: {
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        pb: 4,
    }
}
export default function CardPrice(props: CardPriceProps) {
    return <Stack direction="column" sx={styles.box} height={"150px"}>
        <Typography color={props.highlighted ? "primary.dark" : undefined} sx={styles.title}>
            {props.price.split(" ", 2)[0]}
        </Typography>
        <Typography color={props.highlighted ? "primary.dark" : undefined} sx={[styles.title, {fontSize: 24}]}>
            {props.price.split(" ").slice(1).join(" ") ?? " "}
        </Typography>
        <Typography sx={styles.comment}>
            {props.comment}
        </Typography>
    </Stack>
};
