import { Grid, Stack } from "@mui/material";
import { Link } from "react-router-dom";

interface FooterProps {

}

const styles = {
    link: {
        color: "black",
    }
}

export default function Footer(props: FooterProps) {
    return <Grid container>
        <Grid item xs={12} p={4} bgcolor={"lightgrey"}>
            <Stack direction="column">
                <Link style={styles.link} to={"/privacy"}>Privacy & Cookies</Link>
                <Link style={styles.link} to={"/terms"}>Terms & Conditions</Link>
            </Stack>
        </Grid>
    </Grid>
}