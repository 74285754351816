import { Stack } from "@mui/material";
import { useLanguage } from "../../../state/language";
import LanguageSelect from "./LanguageSelect";
import HeaderNavigationButton from "./HeaderNavigationButton";

interface HeaderButtonsProps {};

const styles = {
    stack: {
        justifyContent: "space-evenly",
        alignItems: "center"
    }
};

export default function HeaderButtons(props: HeaderButtonsProps) {
    const language = useLanguage();

    return <Stack direction="row" sx={styles.stack}>
        <HeaderNavigationButton route={"/pricing"}>{language.portal.pricing}</HeaderNavigationButton>
        <HeaderNavigationButton route={"/documentation"}>{language.portal.documentation}</HeaderNavigationButton>
        <HeaderNavigationButton route={"/download"}>{language.portal.downloadApp}</HeaderNavigationButton>
        <HeaderNavigationButton route={"/signup"}>{language.portal.signup}</HeaderNavigationButton>
        <LanguageSelect />
    </Stack>
}