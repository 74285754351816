import { Navigate } from "react-router-dom";
import { IRoute } from "./types";
import { CompanySignupPage, DownloadAppPage, PricingPage, DocumentationPage } from "../features/";
import SingupConfirmationPage from "../features/signup/pages/SignupConfirmationPage";
import PrivacyPage from "../features/privacy/pages/Privacy";
import TermsAndConditionsPage from "../features/privacy/pages/TermsAndConditions";


export const routeConfigs: Array<IRoute> = [
    {
        path: "/",
        component: <Navigate to="/pricing"/>
    },
    {
        path: "/pricing",
        component: <PricingPage />
    },
    {
        path: "/download",
        component: <DownloadAppPage />
    },
    {
        path: "/documentation",
        component: <DocumentationPage />
    },
    {
        path: "/signup",
        component: <CompanySignupPage />
    },
    {
        path: "/signup/confirmation",
        component: <SingupConfirmationPage />
    },
    {
        path: "/privacy",
        component: <PrivacyPage />
    },
    {
        path: "/terms",
        component: <TermsAndConditionsPage />
    }
]