import { Box, IconButton, Link, Stack, Typography } from "@mui/material"

interface PlatformDownloadProps {
    icon: JSX.Element
    name: string
};

const styles = {
    stack: {
        alignItems: "center"
    },
    button: {
        border: "1px solid"
    }
}
export default function PlatformDownload(props: PlatformDownloadProps) {
    let env = "prod";
    if (window.location.origin.includes("dev.loci.expert")) {
        env = "dev"
    } else if (window.location.origin.includes(":3001")) {
        env = "dev"
    }
    return <Stack direction="column" spacing={2} sx={styles.stack}>
        <Typography>{props.name}</Typography>
        <Box>
            {
                props.name.toLowerCase() === "android" ? 
            
                <IconButton 
                    sx={styles.button}
                    color={"primary"}
                    onClick={() => {
                        window.open(`https://loci-portal-frontend-bucket-${env}.s3.eu-west-1.amazonaws.com/loci-${props.name.toLowerCase()}.apk`, "_blank")
                    }}>{props.icon}
                </IconButton> : <Link href="https://apps.apple.com/be/app/loci-expert/id6477861880?l=nl">
                    <IconButton 
                        sx={styles.button}
                        color={"primary"}
                    >
                        {props.icon}
                    </IconButton>
                </Link>
            }
                
        </Box>
    </Stack>
};
