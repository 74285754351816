import { Container, Stack, Typography, Link } from "@mui/material";
import { useLanguage } from "inspector-common/lib/language";

interface DocumentationPageProps {

}

const styles = {
    container: {
        textAlign: "center",
        px: {lg: 48, md: 36, xs: 4},
        py: {lg: 12, md: 6, xs: 2}
    },
    title: {
        fontSize: 48
    },
    stack: {
        py: 4,
        justifyContent: "space-evenly"
    }
}

export default function DocumentationPage(props: DocumentationPageProps) {
    const language = useLanguage();

    return <Container sx={styles.container}>
        <Typography sx={styles.title}>{language.portal.documentation}</Typography>
        <Stack direction="column" sx={styles.stack}>
            {
                ["NL", "EN", "FR"].map((l: string) => {
                    return <Link onClick={() => {
                        window.open(`https://loci-portal-frontend-bucket-prod.s3.eu-west-1.amazonaws.com/manual-${l}.pdf`, "_blank")
                    }}
                >{language.generic.download} {l}</Link>
                })
            }
            
        </Stack>
    </Container>
}