import { IconButton, Stack } from "@mui/material";
import { useLanguageState } from "../../../state/language";
import languageList, { LanguageList } from "inspector-common/lib/language/list";
import { NL, FR, GB } from "country-flag-icons/react/3x2";

interface LanguageSelectProps {};

export default function LanguageSelect(props: LanguageSelectProps) {
    const { setLanguage } = useLanguageState();

    const flagMapping = {
        nl: <NL title={"Dutch"} width={"20"}/>,
        fr: <FR title={"French"} width={"20"}/>,
        en: <GB title={"English"} width={"20"}/>
    };

    return <Stack direction="row">
        {
            Object.keys(languageList).map((value: string) => {
                return <IconButton onClick={() => setLanguage(value as keyof LanguageList)} key={value}>
                    {(flagMapping as any)[value]}
                </IconButton>
            })
        }
    </Stack>
};
