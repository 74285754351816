import { AppBar, Stack, useMediaQuery } from "@mui/material";
import HeaderTitle from "./HeaderTitle";
import HeaderButtons from "./HeaderButtons";
import MobileHeader from "./MobileMenu";

interface HeaderProps {};

const styles = {
    mainStack: {
        justifyContent: "space-between",
        alignItems: "center",
        paddingX: 4
    },
    appBar: {
        backgroundColor: "white"
    }
}
export default function Header(props: HeaderProps) {
    return <AppBar position="static" sx={styles.appBar}>
        <Stack direction="row" sx={styles.mainStack}>
            <HeaderTitle />
            { useMediaQuery('(min-width:800px)') ? <HeaderButtons /> : <MobileHeader /> }
        </Stack>
    </AppBar>
}