import { Container, Grid, Stack } from "@mui/material";
import usePricingConfig from "../config";
import ContactCard from "../components/ContactCard";
import PricingCard from "../components/PricingCard";
import Intro from "../components/Intro";

interface PricingPageProps {

};

const styles = {
    container: {
        py: 10,
        px: {xs: 0, md: 2}
    },
    stack: {
        width: "100%",
        justifyContent: "center",
        alignItems: "stretch"
    },
    item: {
        justifyContent: "center",
        display: "flex",
        flexDirection: "row",
    }
}

export default function PricingPage(props: PricingPageProps) {
    const pricingConfigs = usePricingConfig();

    return <Container sx={styles.container}>
        <Intro />
        <Grid container sx={styles.stack} spacing={{md: 6, xs: 6}} alignItems={"center"}>
            <Grid xs={12} md={4} item sx={styles.item}><PricingCard config={pricingConfigs.free} /></Grid>
            <Grid xs={12} md={4} item sx={styles.item}><PricingCard config={pricingConfigs.professional} /></Grid>
            <Grid xs={12} md={4} item sx={styles.item}><ContactCard /></Grid>
        </Grid>
    </Container>
};
