import React from 'react';

import { Route, HashRouter as Router, Routes, useLocation } from 'react-router-dom'
import { IRoute } from './types';
import { routeConfigs } from "./routes";
import { Box } from '@mui/material';
import HeaderWrapper from '../features/header/components/HeaderWrapper';

const MyRouter = () => {
    const routes = RouteConfigs(routeConfigs)

    return <Router>
        <ScrollToTop>
            <Box className="container"><Routes>{routes}</Routes></Box>
        </ScrollToTop>
    </Router>

};

const RouteConfigs = (routes: Array<IRoute>) => {
    return routes.map((route) => (
        <Route
            key={route.path}
            path={route.path}
            element={<HeaderWrapper>{route.component}</HeaderWrapper>}
        />))
};


const ScrollToTop = (props: any) => {
    const location = useLocation();
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return <>{props.children}</>
};

export default MyRouter;