import {Container, Stack } from "@mui/material"
import Header from "./Header"
import Footer from "./Footer"

interface HeaderWrapperProps {
    children: JSX.Element[] | JSX.Element
};

const styles = {
    container: {
        flexGrow: 1,
        minHeight: "100vh",
        justifyContent: "space-between"
    }
}
export default function HeaderWrapper(props: HeaderWrapperProps) {
    return <Stack direction="column" sx={styles.container}>
        <Header />
        <Container>{props.children}</Container>
        <Footer />
    </Stack>
};
