import SubscriptionType from "inspector-common/lib/model/company/SubscriptionType"
import { useLanguage } from "../../state/language"

export interface PricingConfig {
    title: string
    subscriptionType: SubscriptionType
    description: string
    highlighted: boolean
    subscriptionPrice: string
    pricingComment: string
    includedFeatures: string[]
    excludedFeatures: string[]
};


export default function usePricingConfig() {
    const language = useLanguage();
    const freeTrial: PricingConfig = {
        title: language.portal.free,
        subscriptionType: SubscriptionType.FREE,
        description: "",
        highlighted: false,
        subscriptionPrice: language.portal.free,
        pricingComment: language.portal.freeTrial,
        includedFeatures: language.portal.freeTrialFeatures,
        excludedFeatures: language.portal.excludedFreeTrialFeatures
    };

    const professional: PricingConfig = {
        title: language.portal.pro,
        subscriptionType: SubscriptionType.PROFESSIONAL,
        description: "",
        highlighted: true,
        subscriptionPrice: language.portal.startUpFee(100),
        pricingComment: language.portal.pricingPerItem(25),
        includedFeatures: language.portal.professionalFeatures,
        excludedFeatures: []
    };

    return {
        free: freeTrial,
        professional: professional
    };
};