import { ConfirmCompanyRegistrationAPICall, ConfirmCompanyRegistrationRequest, ConfirmCompanyRegistrationResponse, CreateFirstUserAPICall, CreateFirstUserRequest, CreateFirstUserResponse } from "inspector-common/lib/api/calls/company";
import { useAPICall } from "./base";
import client from "./client";
import { ContactAPICall, ContactRequest, ContactResponse, RegisterCompanyAPICall, RegisterCompanyRequest, RegisterCompanyResponse } from "inspector-common/lib/api/calls";

async function registerCompany(request: RegisterCompanyRequest): Promise<RegisterCompanyResponse> {
    const call = new RegisterCompanyAPICall(request);
    return client.makeAPICall(call);
};

const useRegisterCompany = () => {
    return useAPICall<RegisterCompanyRequest, RegisterCompanyResponse>(null, async (request: RegisterCompanyRequest) => {
        return await registerCompany(request);
    });
};

async function confirmCompanyRegistration(request: ConfirmCompanyRegistrationRequest): Promise<ConfirmCompanyRegistrationResponse> {
    const call = new ConfirmCompanyRegistrationAPICall(request);
    return client.makeAPICall(call);
};

const useConfirmCompanyRegistration = () => {
    return useAPICall<ConfirmCompanyRegistrationRequest, ConfirmCompanyRegistrationResponse>(null, async (request: ConfirmCompanyRegistrationRequest) => {
        return await confirmCompanyRegistration(request);
    });
};

async function createFirstUser(request: CreateFirstUserRequest): Promise<CreateFirstUserResponse> {
    const call = new CreateFirstUserAPICall(request);
    return client.makeAPICall(call);
};

const useCreateFirstUser = () => {
    return useAPICall<CreateFirstUserRequest, CreateFirstUserResponse>(null, async (request: CreateFirstUserRequest) => {
        return await createFirstUser(request);
    });
};

async function contact(request: ContactRequest): Promise<ContactResponse> {
    const call = new ContactAPICall(request);
    return client.makeAPICall(call);
};

const useContact = () => {
    return useAPICall<ContactRequest, ContactResponse>(null, async (request: ContactRequest) => {
        return await contact(request);
    });
};

export { useConfirmCompanyRegistration, useRegisterCompany, useCreateFirstUser, useContact };