import { List } from "@mui/material"
import CardFeature from "./CardFeature"

interface CardFeaturesProps {
    included: string[]
    excluded: string[]
};

export default function CardFeatures(props: CardFeaturesProps) {
    return <List>
        {
            props.included.map((feature: string, index: number) => {
                return <CardFeature key={index.toString()} feature={feature} included/>
            })
        }
        {
            props.excluded.map((feature: string, index: number) => {
                return <CardFeature key={index.toString()} feature={feature} included={false}/>
            })
        }
    </List>
}