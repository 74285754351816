import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useConfirmCompanyRegistration } from "../../../api/hooks";
import { CircularProgress, Container, Typography } from "@mui/material";
import UserSignUpForm from "../components/UserSignUpForm";
import { useLanguage } from "../../../state/language";

interface SignupConfirmationPageProps {};

const styles = {
    container: {
        width: "500px",
        p: 4,
        mt: {lg: 10, md: 6, sx: 0},
        boxShadow: 12
    },
}

export default function SingupConfirmationPage(props: SignupConfirmationPageProps) {
    const language = useLanguage();
    const [success, setSuccess] = useState<null | boolean>(null);
    const [userSuccess, setUserSuccess] = useState<boolean>(false);
    const code = new URLSearchParams(useLocation().search).get("code");
    const companyID = new URLSearchParams(useLocation().search).get("company_id");

    const { execute: confirmRegistration, isLoading: isConfirmingRegistration } = useConfirmCompanyRegistration();
    useEffect(() => {
        if (code && companyID) {
            confirmRegistration({code, company_id: companyID}).then((response) => {
                if (response?.company) {
                    setSuccess(true);
                } else {
                    setSuccess(false);
                }
            }).catch(() => setSuccess(false));
        } else {
            setSuccess(false);
        }
    }, [code, companyID])
    if (success === null) {
        return <></>
    }
    else if (isConfirmingRegistration) {
        return  <Container sx={styles.container}><CircularProgress /></Container>
    } else if (success === false) {
        return  <Container sx={styles.container}>{language.company.registration.errors.invalidCode}</Container>
    } else if (userSuccess) {
        return <Container sx={styles.container}>
            <Typography>{language.company.registration.seeUserEmail}</Typography>
            <Typography>{language.company.registration.resendEmailMessage}</Typography>
        </Container>
    } else {
        return <UserSignUpForm code={code ?? ""} company_id={companyID ?? ""} onSuccess={() => setUserSuccess(true)} />
    }
}