const cardStyles = {
    card: {
        width: "100%",
        height: "100%",
        maxWidth: "500px",
        boxShadow: "12",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    },
    title: {
        textAlign: "center",
        fontSize: 24,
    },
    content: {
        flexGrow: 1,
    },
    actions: {
        height: "100px",
        justifyContent: "center"
    }
};

export default cardStyles;