export function isValidVat(vat) {
    var validVatRegexes = [
        "^(BE){0,1}[0]{0,1}[0-9]{9}$",
        "^(NL)(\d{9}B\d{2}$)",
        "^(FR)([0-9A-Z]{2}[0-9]{9}$)"
    ];
    for (var _i = 0, validVatRegexes_1 = validVatRegexes; _i < validVatRegexes_1.length; _i++) {
        var r = validVatRegexes_1[_i];
        if (new RegExp(r).test(vat)) {
            return true;
        }
    }
    return false;
}
;
export function isValidPhoneNumber(phone) {
    return new RegExp("^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,6}$").test(phone);
}
;
export function isValidEmail(email) {
    return new RegExp("[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+").test(email);
}
