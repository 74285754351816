import SubscriptionType from "inspector-common/lib/model/company/SubscriptionType";
import { useLocation } from "react-router-dom";
import usePricingConfig from "../../pricing/config";

export function useSubscriptionType() {
    const pricingConfig = usePricingConfig();

    var subscriptionType: SubscriptionType;
    const subscriptionTypeQuery = new URLSearchParams(useLocation().search).get("subscriptionType");
    subscriptionType = SubscriptionType[(subscriptionTypeQuery ?? "") as keyof typeof SubscriptionType] ?? SubscriptionType.PROFESSIONAL
    let config = pricingConfig.professional
    if (subscriptionType === SubscriptionType.FREE) {
        config = pricingConfig.free
    }
    // For now, we don't support PROFESSIONAL Signup. Users should register for free and then upgrade.
    return { subscriptionType: SubscriptionType.FREE, config }
}