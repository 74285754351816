import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { ListItem, Stack, Typography } from '@mui/material';

interface CardFeatureProps {
    included: boolean
    feature: string
};

const styles = {
    featureText: {

    }
};

export default function CardFeature(props: CardFeatureProps) {
    return <ListItem>
        <Stack direction="row" spacing={2}>
            {props.included ? <CheckCircleOutlineIcon color="primary" /> : <CloseIcon />}
            <Typography sx={styles.featureText}>{props.feature}</Typography>
        </Stack>
</ListItem>
}