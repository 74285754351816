import { Card, CardActions, CardContent, CardHeader } from "@mui/material"
import { PricingConfig } from "../config"
import CardPrice from "./CardPrice";
import CardFeatures from "./CardFeatures";
import TryNowButton from "./TryNowButton";
import cardStyles from "../styles/cardStyles";

interface PricingCardProps {
    config: PricingConfig
};

export default function PricingCard(props: PricingCardProps) {
    return <Card sx={cardStyles.card}>
        <CardHeader title={props.config.title} titleTypographyProps={{sx: cardStyles.title}}/>
        <CardContent sx={cardStyles.content}>
            <CardPrice price={props.config.subscriptionPrice} comment={props.config.pricingComment} highlighted={props.config.highlighted}/>
            <CardFeatures included={props.config.includedFeatures} excluded={props.config.excludedFeatures} />
        </CardContent>
        <CardActions sx={cardStyles.actions}>
            <TryNowButton subscriptionType={props.config.subscriptionType} highlighted={props.config.highlighted}/>
        </CardActions>
    </Card>
}