var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Auth } from "aws-amplify";
var DEV_URL = "https://api.dev.loci.expert";
var PROD_URL = "https://api.loci.expert";
var Client = /** @class */ (function () {
    function Client(env) {
        var _a, _b, _c, _d;
        if (process.env.REACT_APP_ENV || env) {
            this.apiUrl = (_c = {
                "dev": DEV_URL,
                "prod": PROD_URL
            }[(_b = (_a = process.env.REACT_APP_ENV) !== null && _a !== void 0 ? _a : env) !== null && _b !== void 0 ? _b : "prod"]) !== null && _c !== void 0 ? _c : PROD_URL;
        }
        else if ((_d = window === null || window === void 0 ? void 0 : window.location) === null || _d === void 0 ? void 0 : _d.origin) {
            switch (window.location.origin) {
                case "http://localhost:3000":
                    this.apiUrl = DEV_URL;
                    break;
                case "http://localhost:3001":
                    this.apiUrl = DEV_URL;
                    break;
                case "http://localhost:3002":
                    this.apiUrl = PROD_URL;
                    break;
                case "https://portal.dev.loci.expert":
                    this.apiUrl = DEV_URL;
                    break;
                case "https://dev.loci.expert":
                    this.apiUrl = DEV_URL;
                    break;
                default:
                    this.apiUrl = PROD_URL;
            }
            ;
        }
        else {
            this.apiUrl = PROD_URL;
        }
        this.token = "";
        this.companyID = "";
        this.userID = "";
    }
    ;
    Client.prototype.setToken = function (token) {
        this.token = token;
    };
    Client.prototype.configure = function (auth) {
        Auth.configure(auth);
    };
    Client.prototype.getAuthHeaders = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, Auth.currentSession().then(function (session) {
                        return {
                            "Authorization": "Bearer ".concat(session.getIdToken().getJwtToken()),
                            "Content-Type": "application/json"
                        };
                    })];
            });
        });
    };
    Client.prototype.makeAPICall = function (call) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, this.getAuthHeaders().then(function (headers) {
                        var url = "".concat(_this.apiUrl).concat(call.path);
                        if (call.request.queryParameters !== undefined && call.request.queryParameters !== null) {
                            url = "".concat(url, "?").concat(Object.keys(call.request.queryParameters).map(function (key) {
                                return "".concat(key, "=").concat(encodeURIComponent(call.request.queryParameters[key]));
                            }).join("&"));
                        }
                        ;
                        console.log("".concat(call.method, " call to ") + url);
                        return window.fetch(url, {
                            method: call.method,
                            headers: headers,
                            body: call.method !== "GET" ? JSON.stringify(call.request.body) : undefined
                        }).then(function (response) { return response.json(); });
                    })];
            });
        });
    };
    ;
    return Client;
}());
;
export default Client;
