import { Button } from "@mui/material"
import { useLanguage } from "../../../state/language"
import { useNavigate } from "react-router-dom";
import SubscriptionType from "inspector-common/lib/model/company/SubscriptionType";

interface TryNowButtonProps {
    subscriptionType: SubscriptionType
    highlighted: boolean
};

export default function TryNowButton(props: TryNowButtonProps) {
    const language = useLanguage();
    const navigate = useNavigate();

    return <Button 
        onClick={() => navigate(`/signup?subscriptionType=${props.subscriptionType}`)}
        variant={props.highlighted ? "contained" : "outlined"}
    >
        {language.portal.tryNow}
    </Button>
}