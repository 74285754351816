;
var APICall = /** @class */ (function () {
    function APICall(path, method, request) {
        this.path = path;
        this.method = method;
        this.request = request;
    }
    ;
    return APICall;
}());
;
;
export { APICall };
