import { Button, Card, CardActions, CardContent, CardHeader, Stack, TextField, Typography } from "@mui/material";
import cardStyles from "../styles/cardStyles";
import { useLanguage } from "../../../state/language";
import { useState } from "react";
import { Formik, Field, useFormik } from "formik";
import { useContact } from "../../../api/hooks";
import { Check } from "@mui/icons-material";
import { isValidEmail } from "inspector-common/lib/utils/validation";

interface ContactCardProps {};

const styles = {
    emailField: {
        width: "100%"
    },
    messageField: {
        width: "100%",
    }
};

interface ContactMessage {
    email: string
    message: string
}
export default function ContactCard(props: ContactCardProps) {
    const language = useLanguage();
    const { execute, isLoading } = useContact();
    const [success, setSuccess] = useState(false);

    const formik = useFormik<ContactMessage>({
        initialValues: {
            email: "",
            message: ""
        },
        validateOnBlur: true,
        validate: (values) => {
            let errors: any = {};
            if (!isValidEmail(values.email)) {
                errors.email = language.company.registration.errors.email
            }
            if (values.message.length < 10 || values.message.length > 800) {
                errors.message = language.company.registration.errors.length(10, 800)
            }
            return errors;
        },
        onSubmit: async (values: ContactMessage) => {
            return execute(values).then(() => {
                setSuccess(true);
            })
        }
    });

    return <Card sx={cardStyles.card}>
        <CardHeader title={language.portal.contact.title} titleTypographyProps={{sx: cardStyles.title}}/>
        <CardContent sx={cardStyles.content}>
            {
                success ? <Stack direction="column" spacing={8} alignItems={"center"}>
                        <Check color="success" />
                        <Typography textAlign={"center"}>{language.portal.contactReceived}</Typography>
                    </Stack> : <Stack direction="column" spacing={3}>
                    <TextField 
                        name="email"
                        onChange={formik.handleChange}
                        variant="outlined"
                        label={language.portal.contact.email}
                        sx={styles.emailField}
                        error={formik.touched.email && !!formik.errors.email}
                        helperText={formik.errors.email}
                    />
                    <TextField 
                        name="message"
                        value={formik.values.message}
                        onChange={formik.handleChange}
                        variant="outlined"
                        multiline={true}
                        rows={10}
                        label={language.portal.contact.message}
                        sx={styles.messageField}
                        error={formik.touched.message && !!formik.errors.message}
                        helperText={formik.errors.message}
                    />
                </Stack>
            }
       
    
        </CardContent>
        <CardActions sx={cardStyles.actions}>
            <Button
                variant="outlined"
                onClick={() => {
                    formik.handleSubmit();
                }}
                disabled={isLoading}
            >
                {language.portal.contact.send}
            </Button>
        </CardActions>
    </Card>}