import { Stack, Typography } from "@mui/material";
import { MuiMarkdown } from "mui-markdown";
interface PrivacyPageProps {};

const styles = {
    title: {

    }
}
const s = `
**Cookie and Privacy Statement for LOCI Web Application**

<br/>
This Cookie and Privacy Statement applies to the use of the LOCI web application, developed by AG IT Solutions and owned by Plan² BV, both Belgian companies. This statement outlines the collection, storage, and usage of personal data by these entities.

`

export default function PrivacyPage(props: PrivacyPageProps) {
    return <Stack direction="column" p={{xs: 2, lg: 12}} spacing={2}>
        <Typography variant="h4">Cookie and Privacy Statement for LOCI Web Application</Typography>
        <Typography>
            This Cookie and Privacy Statement applies to the use of the LOCI web application, developed by AG IT Solutions and owned by Plan² BV, both Belgian companies. This statement outlines the collection, storage, and usage of personal data by these entities.
        </Typography>
        <Typography variant="h6">
            1. Information Collected:
        </Typography>
        <MuiMarkdown>
{`
When using the LOCI web application, AG IT Solutions and Plan² BV may collect and store personal data necessary for authentication purposes. This may include but is not limited to:

* Full Name
* Email Address
* User Credentials (username and password)`}
</MuiMarkdown>
<Typography variant="h6">
2. Purpose of Data Collection:
</Typography>

<MuiMarkdown>
{`The collected personal data is essential for the proper functioning and authentication processes of the LOCI web application. AG IT Solutions and Plan² BV do not use this data for any purpose other than facilitating user access and maintaining the security of the application.`}
</MuiMarkdown>

<Typography variant="h6">3. Data Storage</Typography>
<MuiMarkdown>
All personal data collected by AG IT Solutions and Plan² BV is securely stored and maintained on servers hosted within the European Union.
</MuiMarkdown>

<Typography variant="h6">4. Third-Party Sharing</Typography>

<MuiMarkdown>
AG IT Solutions and Plan² BV affirm that they do not share any personal data collected through the LOCI web application with third parties.
</MuiMarkdown>

<Typography variant="h6">5. User Control</Typography>
<MuiMarkdown>
Users have the right to request the deletion of their personal information from the LOCI web application. For any privacy-related inquiries, users can contact the Data Protection Officer at privacy@agits.be.
</MuiMarkdown>


<Typography variant="h6">6. Cookies:</Typography>

<MuiMarkdown>
The LOCI web application may use cookies to enhance user experience and for authentication purposes. These cookies do not store any personally identifiable information and are used solely for the proper functioning of the application.
</MuiMarkdown>

<Typography variant="h6">7. Changes to Privacy Policy:</Typography>

<MuiMarkdown>
AG IT Solutions and Plan² BV reserve the right to modify this Cookie and Privacy Statement. Users will be notified of any significant changes, and it is recommended to review this statement periodically.
</MuiMarkdown>

<Typography variant="h6">8. Contact Information:</Typography>

<MuiMarkdown>
For any questions or concerns regarding privacy, users can contact the Data Protection Officer at privacy@agits.be.
</MuiMarkdown>
</Stack>
}